@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    color: #6B7280;
}

.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-content {
    outline: none;
}

.categories.mapboxgl-popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    display: none;
}

.hover-link:hover,
.hover-link:active {
    border-radius: 0.25rem;
    width: 100%;
    background-color: #E5E7EB;
    padding: 0.25rem;
}

.css-19wsa2m-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ff9494 !important;
}
.css-1sj2jcg-MuiTabs-indicator {
    background-color: #ff9494 !important;
}

.css-19wsa2m-MuiButtonBase-root-MuiTab-root {
    text-transform: lowercase !important;
}

.css-1ewrhg9-MuiPaper-root-MuiAlert-root {
    padding: 2px 5px !important;
}

.left-container {
    width: 100%;
}

.right-container {
    width: 100%;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
    .right-container {
        width: 350px;
        background-color: #ff949480;
        height: calc(100vh - 46px);
    }
    
    .left-container {
        width: calc(100vw - 350px);
    }
}

@media screen and (min-width: 1025px) {
    .right-container {
        width: 450px;
        background-color: #ff949480;
        height: calc(100vh - 46px);
    }
    .left-container {
        width: calc(100vw - 450px);
    }
}